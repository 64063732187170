import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingIndicator from './components/LoadingIndicator'; // Import the LoadingIndicator component

// Lazy load components
const Home = lazy(() => import('./components/Home/Home'));
const Workspace = lazy(() => import('./components/Workspace/Workspace'));
const SignIn = lazy(() => import('./components/Screens/SignIn/Signin'));
const SignUp = lazy(() => import('./components/Screens/SignUp/SignUp'));
const Dashboard = lazy(() => import('./components/Screens/Dashboard/Dashboard'));
const AdminPanel = lazy(() => import('./components/Screens/Admin/AdminPanel'));
const Users = lazy(() => import('./components/Screens/Admin/Users'));
const Referrals = lazy(() => import('./components/Screens/Admin/Referrals'));
const Credits = lazy(() => import('./components/Screens/Admin/Credits'));
const App = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/referrals" element={<Referrals />} />
          <Route path="/admin/credits" element={<Credits />} />
          {/* Other routes */}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
// src/components/LoadingIndicator.js

import React from 'react';
import './LoadingIndicator.css'; // We'll create this CSS file

function LoadingIndicator() {
  return (
    <div className="loading-overlay">
      <div className="loading-indicator">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default LoadingIndicator;